import React from "react";
import IntlMessages from "components/Utils/intlMessages";
import Divider from "components/Divider/Divider";
import {
  Twitter,
  Instagram,
  Facebook,
  Linkedin,
  Youtube,
  Snapchat,
  Whatsapp,
  MapIcon,
  EmailIcon,
  PhoneIcon,
  AddressIcon,
} from "assets/icons";
import { useSelector } from "react-redux";
import Messages from "components/Footers/i18nStrings";
import { useGetStaticPages } from "apis/information";
import { Link } from "react-router-dom";
export default function Footer({ settings, isLoading, locale }) {
  const { config: remoteConfig, isLoading: isRemoteConfigLoading } =
    useSelector((state) => state.RemoteConfig.remoteConfig);
  const { profile, isLoading: isProfileLoading } = useSelector(
    (state) => state.Userprofile.userprofile
  );
  // Conditional fetch based on isRemoteConfigLoading
  const { data, isListLoading } = useGetStaticPages({
    category: "footer",
    enabled: !isRemoteConfigLoading && !isProfileLoading,
  });
  return (
    <>
      <footer className="bg-gray-950 md:text-start text-gray-200 py-5 text-center pb-16 md:pb-5">
        <div className="container pt-5">
          <div className="w-full flex flex-wrap lg:justify-between">
            <div className="mb-7 lg:mb-0 px-4 w-full lg:w-3/12 md:w-6/12 flex flex-col gap-8 items-center md:items-start">
              {locale === "ar" ? (
                <img
                  alt={settings?.general_setting.store_name_ar}
                  src={settings?.general_setting.store_footer_logo_ar}
                  className="w-32"
                />
              ) : (
                <img
                  alt={settings?.general_setting.store_name_en}
                  src={settings?.general_setting.store_footer_logo_en}
                  className="w-32"
                />
              )}
              <p className="text-base">{Messages.getFormatMessage("slogan")}</p>

              <ul className="w-full list-unstyled flex gap-5 justify-center md:justify-start">
                {!isListLoading &&
                  data
                    ?.filter((item) => item.type === "about")
                    .map((item, index) => (
                      <li key={index}>
                        <Link
                          className="text-gray-200 hover:text-gray-200 block pb-2 text-base font-inter-semibold"
                          to={`/account/information/${item.id}`}
                          target="_blank"
                        >
                          {Messages.getFormatMessage("about")}
                        </Link>
                      </li>
                    ))}

                <li>
                  <Link
                    className="text-gray-200 hover:text-gray-200 block pb-2 text-base font-inter-semibold"
                    to="/account/contact-us"
                    target="_blank"
                  >
                    {Messages.getFormatMessage("contact")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mb-7 lg:mb-0 px-4 w-full lg:w-3/12 md:w-6/12 flex flex-col">
              <h2 className="text-lg">
                {Messages.getFormatMessage("policies")}
              </h2>
              <ul className="list-unstyled gap-3">
                {!isListLoading &&
                  data
                    ?.filter((item) => item.type === "policy")
                    .map((item, index) => (
                      <li key={index}>
                        <Link
                          className="text-gray-200 hover:text-gray-200 block pb-2 text-base font-inter-regular"
                          to={`/information/${item.id}`}
                          target="_blank"
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
              </ul>
            </div>
            <div className="mb-7 lg:mb-0 px-4 w-full lg:w-3/12 md:w-6/12 flex flex-col">
              <h2 className="text-lg">
                {Messages.getFormatMessage("contactInfo")}
              </h2>
              {!isLoading && settings?.general_setting && (
                <ul className="list-unstyled flex flex-col gap-1 items-center md:items-start">
                  <li>
                    <a
                      className="flex gap-2 items-center text-gray-200 hover:text-gray-200 pb-2 text-base "
                      href={`mailto:${settings?.general_setting.email}`}
                      target="_blank"
                    >
                      <EmailIcon /> {settings?.general_setting.email}
                    </a>
                  </li>
                  <li>
                    <p className="flex gap-2 items-center text-gray-200  pb-2 text-base ">
                      <PhoneIcon /> {settings?.general_setting.phone_number}
                    </p>
                  </li>
                  <li>
                    <p className="flex gap-2 items-center text-gray-200  pb-2 text-base ">
                      <AddressIcon className="shrink-0" />
                      {locale === "ar"
                        ? settings?.general_setting.address_ar
                        : settings?.general_setting.address_en}
                    </p>
                  </li>
                  {settings?.general_setting.map_url && (
                    <li>
                      <a
                        className="flex gap-2 items-center text-gray-200 hover:text-gray-200 pb-2 text-base "
                        href={settings?.general_setting.map_url}
                        target="_blank"
                      >
                        <MapIcon /> {Messages.getFormatMessage("findUs")}
                      </a>
                    </li>
                  )}
                </ul>
              )}
            </div>
            {(settings?.general_setting?.ios_app_link ||
              settings?.general_setting?.android_app_link) && (
              <div className="mb-7 lg:mb-0 px-4 w-full md:w-auto flex flex-col items-center md:items-start">
                <h2 className="text-lg">
                  {Messages.getFormatMessage("getAppTitle")}
                </h2>
                <ul className="list-unstyled">
                  {settings?.general_setting?.ios_app_link && (
                    <li>
                      <a
                        className="text-gray-200 hover:text-gray-200 block pb-2 text-base font-inter-semibold"
                        href={settings?.general_setting?.ios_app_link}
                      >
                        <img
                          alt="appstore Logo"
                          src={require("assets/images/app-store.png").default}
                          className="h-12"
                        />
                      </a>
                    </li>
                  )}
                  {settings?.general_setting?.android_app_link && (
                    <li>
                      <a
                        className="text-gray-200 hover:text-gray-200 block pb-2 text-base font-inter-semibold"
                        href={settings?.general_setting?.android_app_link}
                      >
                        <img
                          alt="playstore Logo"
                          src={require("assets/images/google-play.png").default}
                          className="h-12"
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="px-4">
            <Divider borderColor="gray-600" />
          </div>
          <div className="w-full flex flex-wrap items-center">
            <div className="px-4 grow text-base ltr:font-inter-medium rtl:font-semibold">
              © {new Date().getFullYear()}{" "}
              <IntlMessages id="global.copyRights" />.
            </div>
            {!isLoading && settings?.social_media ? (
              <div className="px-4 mt-3 md:mt-0 w-full md:w-4/12 flex gap-6 justify-center lg:justify-end ">
                <>
                  {settings?.social_media?.Twitter && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Twitter }}
                      target="_blank"
                    >
                      <Twitter size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Instagram && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Instagram }}
                      target="_blank"
                    >
                      <Instagram size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Facebook && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Facebook }}
                      target="_blank"
                    >
                      <Facebook size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Linkedin && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Linkedin }}
                      target="_blank"
                    >
                      <Linkedin size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Youtube && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Youtube }}
                      target="_blank"
                    >
                      <Youtube size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Snapchat && (
                    <Link
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      to={{ pathname: settings?.social_media?.Snapchat }}
                      target="_blank"
                    >
                      <Snapchat size={20} className="text-current" />
                    </Link>
                  )}
                  {settings?.social_media?.Whatsapp && (
                    <a
                      className="text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none inline-block"
                      href={
                        `https://api.whatsapp.com/send?phone=` +
                        settings?.social_media?.Whatsapp +
                        `&text=%20`
                      }
                      target="_blank"
                    >
                      <Whatsapp size={20} className="text-current" />
                    </a>
                  )}
                </>
              </div>
            ) : null}
          </div>
        </div>
      </footer>
    </>
  );
}
