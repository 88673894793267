/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "components/Buttons/Button";
import IntlMessages from "components/Utils/intlMessages";
import LoginDropdown from "components/Dropdowns/LoginDropdown.js";
import LangDropdown from "components/Dropdowns/LangDropdown.js";
import UserOptionsDropdown from "components/Dropdowns/UserOptionsDropdown.js";
import CurrencyDropdown from "components/Dropdowns/CurrencyDropdown";
import actions from "redux/languageSwitcher/actions";
import { useDispatch, useSelector } from "react-redux";
import Search from "components/Search/SearchForm";
import {
  FavIcon,
  CartIcon,
  NotiIcon,
  UserIcon,
  SearchIcon,
} from "assets/icons";
import AuthContainer from "components/Authentication/AuthContainer";
import { get as _get } from "lodash";
import { actions as orderActions } from "redux/order/actions";
import { actions as selectedServiceActions } from "redux/selectedService/actions";
import BranchesSearch from "components/Search/BranchesSearchForm";
import ProductsSearch from "components/Search/SearchForm";
import MobileNavbar from "./MobileNavbar";
export default function LandingNavbar({ settings, isLoading, locale }) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { serviceDetails, isLoading: isServiceDetailsLoading } = useSelector(
    (state) => state.SelectedService.selectedService
  );
  const [isShowModalSignIn, setIsShowModalSignIn] = useState(false);
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const handleTriggerModalSignIn = () =>
    setIsShowModalSignIn(!isShowModalSignIn);
  const [useDefaultLocation, setUseDefaultLocation] = useState(
    localStorage.getItem("use_default_location")
  );
  return (
    <>
      <AuthContainer
        isShowModalSignIn={isShowModalSignIn}
        handleTriggerModalSignIn={handleTriggerModalSignIn}
      />
      <nav className="sticky top-0  w-full flex-col flex flex-wrap items-center justify-between py-3 navbar-expand-lg bg-white border-b border-gray-80 z-50">
        <div className="container px-4 flex flex-wrap items-center justify-between">
          <div className="w-full lg:w-auto relative flex justify-between lg:static lg:block lg:justify-start">
            <Link
              to={useDefaultLocation ? "/home" : "/"}
              className="inline-block my-2 lg:my-0 me-4"
            >
              {locale === "ar" ? (
                <img
                  alt={settings?.general_setting.store_name_ar}
                  src={settings?.general_setting.store_logo_ar}
                  className="w-32 max-h-10"
                />
              ) : (
                <img
                  alt={settings?.general_setting.store_name_en}
                  src={settings?.general_setting.store_logo_en}
                  className="w-28 max-h-10"
                />
              )}
            </Link>
            <div className="flex md:hidden items-center gap-2">
              <Button
                onClick={() => setSearchOpen(!searchOpen)}
                variant="outlined"
                size="large"
              >
                <SearchIcon size={24} />
              </Button>

              <LangDropdown locale={locale} />
            </div>
            <button
              className="hidden md:block lg:hidden cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div
            className={`w-full flex md:hidden items-center gap-2 bg-white lg:shadow-none ${
              searchOpen ? "block" : "hidden"
            }`}
          >
            {location.pathname !== "/" ? (
              !isServiceDetailsLoading &&
              serviceDetails?.filter_type === "products_focused" ? (
                <ProductsSearch />
              ) : (
                <BranchesSearch />
              )
            ) : null}
          </div>
          <div
            className={`lg:ps-52 lg:w-2/3 lg:flex flex-grow items-center gap-3 bg-white lg:shadow-none ${
              navbarOpen ? "block" : "hidden"
            } ${location.pathname === "/" ? "justify-end" : "justify-between"}`}
            id="navbar"
          >
            {location.pathname !== "/" ? (
              !isServiceDetailsLoading &&
              serviceDetails?.filter_type === "products_focused" ? (
                <ProductsSearch />
              ) : (
                <BranchesSearch />
              )
            ) : null}
            <ul className="flex my-2 md:my-0 gap-3 justify-center lg:justify-start list-none">
              <li className="flex items-center">
                <Button
                  onClick={handleTriggerModalSignIn}
                  variant="solid"
                  className="flex items-center gap-1"
                  size="large"
                >
                  <UserIcon size={20} />

                  <IntlMessages id="global.loginOrRegister" />
                </Button>
              </li>

              <li className="flex items-center">
                <LangDropdown locale={locale} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <MobileNavbar
        locale={locale}
        handleTriggerModalSignIn={handleTriggerModalSignIn}
      />
    </>
  );
}
