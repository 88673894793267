import Messages from "views/Account/i18nStrings";
import IntlMessages from "components/Utils/intlMessages";
import {
  MdCube,
  FillHeartFill,
  Reviews,
  AccountBalanceWallet,
  LocationOn,
  MdCard,
  UserAlt,
  Language,
  GTranslate,
  FillInfoCircle,
  Message,
  AssignmentReturn,
  HomeOutline,
  Apartment,
  BuildingHouse,
  Stars,
  MdMegaphone,
} from "assets/icons";

export const asideLinks = [
  {
    title: "",
    links: [
      {
        icon: <MdCube className="text-white" size={16} />,
        text: <IntlMessages id="account.myOrders" />,
        path: "my-orders",
      },
      {
        icon: <AssignmentReturn className="text-white" size={16} />,
        text: <IntlMessages id="account.returnOrders" />,
        path: "return-orders",
      },

      {
        icon: <FillHeartFill className="text-white" size={16} />,
        text: <IntlMessages id="account.myFavourites" />,
        path: "my-favourites",
      },
      {
        icon: <AccountBalanceWallet className="text-white" size={16} />,
        text: <IntlMessages id="account.myWallet" />,
        path: "my-wallet",
      },
    ],
  },
  {
    title: <IntlMessages id="account.personalInformation" />,
    links: [
      {
        icon: <LocationOn className="text-white" size={16} />,
        text: <IntlMessages id="account.myAddress" />,
        path: "my-address",
      },
      {
        icon: <UserAlt className="text-white" size={16} />,
        text: <IntlMessages id="account.profile" />,
        path: "profile",
      },
    ],
  },
  {
    title: <IntlMessages id="account.points" />,
    links: [
      {
        icon: <Stars className="text-white" size={16} />,
        text: <IntlMessages id="account.loyaltyPoints" />,
        path: "loyalty-points",
      },
      {
        icon: <Stars className="text-white" size={16} />,
        text: <IntlMessages id="account.referralProgram" />,
        path: "referral-program",
      },
      // {
      //   icon:<MdMegaphone className="text-white" size={16} />,
      //   text: <IntlMessages id="account.affiliateProgram" />,
      //   path:"affiliate-program"
      // },
    ],
  },
];

export const asidePublicLinks = [
  // {
  //   title: <IntlMessages id="account.settings" />,
  //   links: [
  //     //{
  //     //  icon: <Language className="text-white" size={16} />,
  //     //  text: <IntlMessages id="account.country" />,
  //     //  path: "country",
  //     //},
  //     // {
  //     //   icon: <GTranslate className="text-white" size={16} />,
  //     //   text: <IntlMessages id="account.preferences" />,
  //     //   path: "preferences",
  //     // },
  //   ],
  // },
  {
    title: <IntlMessages id="account.matjrApp" />,
    links: [
      {
        icon: <FillInfoCircle className="text-white" size={16} />,
        text: <IntlMessages id="account.aboutAbout" />,
        path: "information/1",
      },
      {
        icon: <Message className="text-white" size={16} />,
        text: <IntlMessages id="account.contactUs" />,
        path: "contact-us",
      },
      {
        icon: <Message className="text-white" size={16} />,
        text: <IntlMessages id="account.support" />,
        path: "support",
      },
    ],
  },
];

export const orderStatus = [
  {
    text: <IntlMessages id="account.current" />,
    key: "current",
  },
  {
    text: <IntlMessages id="account.completed" />,
    key: "delivered",
  },
  {
    text: <IntlMessages id="account.rejected" />,
    key: "rejected",
  },
  // {
  //   text: <IntlMessages id="account.cancellations" />,
  //   key: "cancelled",
  // },
  // {
  //   text: <IntlMessages id="account.return" />,
  //   key: "return",
  // },
];
export const returnOrderStatus = [
  {
    text: <IntlMessages id="account.current" />,
    key: "current",
  },
  {
    text: <IntlMessages id="account.completed" />,
    key: "returned",
  },
];

export const refundMethodOptions = [
  {
    id: "wallet",
    text: <IntlMessages id="account.walletMethod" />,
    icon: AccountBalanceWallet,
  },
];

export const addressTypes = [
  {
    id: "home",
    name: <IntlMessages id="account.home" />,
    icon: HomeOutline,
  },
  {
    id: "work",
    name: <IntlMessages id="account.work" />,
    icon: Apartment,
  },
  {
    id: "other",
    name: <IntlMessages id="account.other" />,
    icon: BuildingHouse,
  },
];

export const genderTypes = [
  {
    id: "female",
    name: <IntlMessages id="account.female" />,
  },
  {
    id: "male",
    name: <IntlMessages id="account.male" />,
  },
];
export const languageTypes = [
  {
    id: "ar",
    name: <IntlMessages id="global.arabic" />,
  },
  {
    id: "en",
    name: <IntlMessages id="global.english" />,
  },
];
