import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, Link, useLocation, useRouteMatch } from "react-router-dom";
import { actions as authActions } from "redux/auth/actions";
import {
  OrdersIcon,
  LocationOn,
  AccountBalanceWallet,
  SettingsIcon,
  UserSolidIcon,
  LogoutIcon,
  IosArrowForward,
} from "assets/icons";
import { get as _get } from "lodash";
import IntlMessages from "components/Utils/intlMessages";
import {
  asideLinks,
  asidePublicLinks,
} from "components/Views/Account/Constants";

const AccountSplash = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const User = useSelector((state) => state.User || {}, shallowEqual);
  const isLoggedIn = _get(User, "token", false);

  const checkActive = (path) => pathname.split("/").includes(path);

  const logout = () => {
    dispatch(authActions.logout());
    history.push("/");
  };

  const handleClick = (to) => {
    onClose();
    history.push(to);
  };

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">
          <IntlMessages id="global.accountOptions" />
        </h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-col gap-2 overflow-y-auto">
        {isLoggedIn ? (
          asideLinks.map((group, index) => (
            <div className="mb-4" key={index}>
              {group.title && (
                <p className="mb-3 text-sm leading-2 text-gray-700 ltr:font-inter-medium rtl:font-semibold">
                  {group.title}
                </p>
              )}
              {group.links.map((link, i) => (
                <Link
                  to={`/account/${link.path}`}
                  key={i}
                  onClick={onClose}
                  className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300 ${
                    checkActive(link.path) ? "bg-primary-60" : ""
                  }`}
                >
                  <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
                    {link.icon}
                  </div>
                  <p className="grow text-sm leading-2 text-secondary-950 ltr:font-inter-medium rtl:font-semibold">
                    {link.text}
                  </p>
                  <IosArrowForward
                    className="text-gray-700 rtl:rotate-180"
                    size={15}
                  />
                </Link>
              ))}
            </div>
          ))
        ) : (
          <></>
        )}
        {asidePublicLinks.map((group, index) => (
          <div key={index}>
            {group.title && (
              <p className="mb-3 text-sm leading-2 text-gray-700 ltr:font-inter-medium rtl:font-semibold">
                {group.title}
              </p>
            )}
            {group.links.map((link, i) => (
              <Link
                to={`/account/${link.path}`}
                key={i}
                onClick={onClose}
                className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300 ${
                  checkActive(link.path) ? "bg-primary-60" : ""
                }`}
              >
                <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
                  {link.icon}
                </div>
                <p className="grow text-sm leading-2 text-secondary-950 ltr:font-inter-medium rtl:font-semibold">
                  {link.text}
                </p>
                <IosArrowForward
                  className="text-gray-700 rtl:rotate-180"
                  size={15}
                />
              </Link>
            ))}
          </div>
        ))}
        <button
          onClick={logout}
          className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300`}
        >
          <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
            <LogoutIcon className="text-white" size={16} />
          </div>
          <div className="text-sm leading-2 text-secondary-950 ltr:font-inter-medium rtl:font-semibold">
            <IntlMessages id="global.logout" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default AccountSplash;
